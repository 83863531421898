<template>
  <div class="">
    <h2
      v-if="pkg && pkg.id"
      class="font-600 mb-6"
    >
      <template v-if="item.seller_id === user.id">
        {{ $t("Revue de votre vente") }}
      </template>
      <template v-else>
        {{ $t("Revue de votre achat") }}
      </template>
    </h2>
    <h2
      v-else
      class="font-600 mb-6"
    >
      {{ $t("Écrire un avis pour ce produit") }}
    </h2>
    <h5 class="font-600 mb-3">
      {{ $t("Votre note") }} <sup class="primary--text">*</sup>
    </h5>
    <v-rating
      v-model="rating"
      :value="0"
      color="amber"
      class="mb-3"
      background-color="amber"
      dense
      half-increments
      size="25"
    />
    <h5 class="font-600 mb-3">
      {{ $t("Votre avis") }} <sup class="primary--text">*</sup>
    </h5>
    <v-textarea
      v-model="comment"
      color="primary"
      class="mb-3"
      outlined
      background-color="white"
    />
    <h3
      v-if="singleProductData && !singleProductData.can_review"
      class="font-600 mb-6 error--text"
    >
      {{ $t("Désolé, vous ne pouvez commenter qu'une seule fois lorsque vous achetez ce produit.") }}
    </h3>
    <!--    <h3
      v-if="pkg && pkg.id"
      class="font-600 mb-6 error&#45;&#45;text"
    >
      {{ $t("Désolé, vous ne pouvez commenter qu'une seule fois lorsque vous achetez ce produit.") }}
    </h3>-->
    <v-btn
      :loading="loading"
      :disabled="comment.trim().length < 10 || rating === 0 || ((singleProductData && !singleProductData.can_review) && !pkg.id)"
      color="primary"
      class="text-capitalize"
      @click="save"
    >
      {{ $t("Soumettre") }}
    </v-btn>
  </div>
</template>

<script>

  import { mapActions, mapState } from 'vuex'

  export default {
    directives: {
    },
    components: {
    },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
      pkg: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      singleProductData: {},
      rating: 0,
      comment: '',
      loading: false,
    }),
    computed: {
      ...mapState({
        user: 'user',
        displayNotification: 'display_notification',
      }),
    },
    created () {
      this.singleProductData = this.product
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      save () {
        this.loading = true
        axios.post('/reviews', {
          rating: this.rating,
          comment: this.comment,
          product_id: this.product.id,
          package_id: this.pkg.id,
        }).then((response) => {
          this.loading = false
          if (response.data && response.data.id) {
            this.singleProductData.reviews.push(response.data.id)
            this.singleProductData.can_review = false
            this.$emit('commented', this.singleProductData)
          }
        }).then(() => {
          this.loading = false
        })
      },

    },
  }
</script>
